<!--
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
-->
<template>
  <div class="bg-white">
    <div class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8" v-if="product">
      <div class="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
        <!-- Image gallery -->
        <TabGroup as="div" class="flex flex-col-reverse">
          <!-- Image selector -->
          <div class="hidden mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
            <TabList class="grid grid-cols-4 gap-6">
              <Tab v-for="image in product.pictures" :key="image.id" class="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50" v-slot="{ selected }">
                <span class="absolute inset-0 rounded-md overflow-hidden">
                  <img :src="image.image" alt="" class="w-full h-full object-center object-cover" />
                </span>
                <span :class="[selected ? 'ring-base-500' : 'ring-transparent', 'absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none']" aria-hidden="true" />
              </Tab>
            </TabList>
          </div>

          <TabPanels class="w-full aspect-w-1 aspect-h-1">
            <TabPanel v-for="image in product.pictures" :key="image.id">
              <img :src="image.image" class="w-full h-full object-center object-cover sm:rounded-lg" />
            </TabPanel>
          </TabPanels>
        </TabGroup>

        <!-- Product info -->
        <div class="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
          <h1 class="text-2xl font-extrabold tracking-tight text-gray-800">{{ product.name }}</h1>
          <div class="flex items-center">
            <img class="w-10 h-10 rounded-full border border-gray-500 mr-2" :src="product.provider.picture"/>
            <p class="text-gray-600 font-semibold">{{product.provider.name}}</p>
          </div>

          <div class="my-4">
            <div class="sm:hidden">
              <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
              <select @change="select_tab($event.target.value)" id="tabs" name="tabs" class="block w-full focus:ring-base-500 focus:border-base-500 border-gray-300 rounded-md">
                <option :value="tab.name" class="w-full" v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ loc[tab.name] }}</option>
              </select>
            </div>
            <div class="hidden sm:block">
              <nav class="flex space-x-4" aria-label="Tabs">
                <div @click="select_tab(tab.name)" v-for="tab in tabs" :key="tab.name" :class="[tab.current ? 'bg-blue-100 text-base-700' : 'text-gray-500 hover:text-gray-700', 'px-3 py-2 font-medium text-sm rounded-md cursor-pointer']" :aria-current="tab.current ? 'page' : undefined">
                  {{ loc[tab.name] }}
                </div>
              </nav>
            </div>
          </div>

          <div v-if="current_tab.key == 'promo_material'" class="flex flex-wrap">
            <a :href="doc.file" target="_blank" v-for="doc in product[current_tab.key]">
              <DocumentIcon
                            class="w-10 h-10 text-gray-600 hover:text-base-600 cursor-pointer"
              />
              <p>{{ doc.title }}</p>
            </a>
          </div>
          <div v-else v-html="product[current_tab.key]"></div>
          <div v-if="current_tab.key == 'short_description'" class="divide-y pt-6 text-lg text-gray-600">
            <div class="flex justify-between p-1">
              <p class="font-bold">{{loc.category}}:</p>
              <p>{{product.category[0].name}}</p>
            </div>
            <div class="flex justify-between p-1" v-if="product.category.length == 2">
              <p class="font-bold">{{loc.subcategory}}:</p>
              <p>{{product.category[1].name}}</p>
            </div>
            <div class="flex justify-between p-1">
              <p class="font-bold">{{loc.category}}:</p>
              <p>{{product.category[0].name}}</p>
            </div>
          </div>

          <div class="mt-3" v-if="!product.fixed && !product.special_offer">
            <div class="flex justify-start text-base-700">
              <p class="text-xl font-bold">{{ product.price_discount_currency }}</p>
              <div class="tooltip">
                <InformationCircleIcon class="w-6 h-6 ml-2" />
                <span class="tooltiptext"><div v-html="product.note"></div></span>
              </div>
            </div>
            <p class="text-gray-500 text-sm">{{loc.vat}}</p>
          </div>


          <div class="mt-6">

            <div class="mt-10 flex sm:flex-col1" v-if="$store.state.auth.user">
              <button v-if="!product.fixed && !product.special_offer" @click="$store.dispatch('auth/toggleCart', product.id)" class="max-w-xs flex-1 bg-base-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-base-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-base-500 sm:w-full">
                {{$store.state.auth.user.cart.findIndex(e=>e.benefit.id == product.id) > -1 ? loc.remove_cart : loc.add_cart}}
              </button>

              <button @click="$store.dispatch('auth/toggleFavorites', product.id)" type="button"
                      class="ml-4 py-3 px-3 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500">
                <HeartFull class="w-6 h-6 text-red-600" v-if="$store.state.auth.user.favorite.findIndex(e=>e.id == product.id) > -1"/>
                <HeartIcon v-else class="h-6 w-6 flex-shrink-0" aria-hidden="true" />

              </button>
            </div>
          </div>

          <section aria-labelledby="details-heading" class="mt-12">
            <h2 id="details-heading" class="sr-only">Additional details</h2>

            <div class="border-t divide-y divide-gray-200">
              <Disclosure as="div" v-for="detail in product.details" :key="detail.name" v-slot="{ open }">
                <h3>
                  <DisclosureButton class="group relative w-full py-6 flex justify-between items-center text-left">
                    <span :class="[open ? 'text-base-600' : 'text-gray-900', 'text-sm font-medium']">
                      {{ detail.name }}
                    </span>
                    <span class="ml-6 flex items-center">
                      <PlusSmIcon v-if="!open" class="block h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                      <MinusSmIcon v-else class="block h-6 w-6 text-base-400 group-hover:text-base-500" aria-hidden="true" />
                    </span>
                  </DisclosureButton>
                </h3>
                <DisclosurePanel as="div" class="pb-6 prose prose-sm">
                  <ul role="list">
                    <li v-for="item in detail.items" :key="item">{{ item }}</li>
                  </ul>
                </DisclosurePanel>
              </Disclosure>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { ref } from 'vue'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/vue'
import { StarIcon, DocumentIcon, InformationCircleIcon, HeartIcon as HeartFull } from '@heroicons/vue/solid'
import { HeartIcon, MinusSmIcon, PlusSmIcon } from '@heroicons/vue/outline'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    StarIcon,
    HeartIcon,
    MinusSmIcon,
    PlusSmIcon,
    DocumentIcon,
    InformationCircleIcon,
    HeartFull
  },
  data() {
    return {
      product: null,
      tabs: [
          { name: 'details', href: '#', current: true, key:'short_description' },
          { name: 'description', href: '#', current: false, key:'description' },
          { name: 'instructions', href: '#', current: false, key:'instructions' },
          { name: 'limitations', href: '#', current: false, key:'limitations' },
        { name: 'promo', href: '#', current: false, key:'promo_material' },
      ]
    }
  },
  computed: {
    current_tab() {
      return this.tabs.find(e=>e.current)
    }
  },
  methods: {
    select_tab(name) {
      this.tabs = this.tabs.map(e=>{
        return {...e, current: e.name == name}
      })
    }
  },
  async mounted() {
    let {data} = await this.axios.get(`v2/benefit/${this.$route.params.id}/`)
    this.product = data
  }
}
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: rgba(0, 0, 0, 0.71);
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>